import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Modal from "../components/modal"
// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import "./index.css"

// const images = [
//   {
//     thumbimg: "/assets/img/thumb-1.jpg",
//     img: "assets/img/Asia_Oceans_AUS.png",
//   },
//   {
//     thumbimg: "/assets/img/thumb-2.jpg",
//     img: "assets/img/N-C-S-Amer_Pacific-Ocean.png",
//   },
//   {
//     thumbimg: "/assets/img/thumb-3.jpg",
//     img: "assets/img/Baja-NA-qtr-rnd.jpg",
//   },
//   { thumbimg: "/assets/img/thumb-4.jpg", img: "assets/img/africa-clr.png" },
// ]

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }
  render() {
    // const { photoIndex, isOpen } = this.state
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <section id="just-earth" className="component">
          <div className="container">
            <div className="content text-white">
              <ScrollAnimation
                animateIn="fadeInDown"
                initiallyVisible={false}
                animateOnce={true}
              >
                <p>
                  Just Earth . . . slowly rotating in the vastness of our Solar
                  System. One shining blue living planet against the darkness of
                  Space.
                </p>
                <p>
                  Green and brown land masses are scattered around but appear to
                  be puzzle pieces reaching out for each other; continents
                  painted on the surface of immense oceans of blue that cover
                  nearly everything.
                </p>
                <p>
                  And ALL of this, along with the entire human experience, is
                  wrapped in a thin veil of atmosphere with its global blanket
                  of spinning white clouds.
                </p>
                <p>How do you feel now?</p>
              </ScrollAnimation>
            </div>
          </div>
        </section>
        <section
          id="we-live"
          className="component bg-black"
          style={{
            backgroundImage: 'url("assets/img/bg5.jpg")',
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="content text-white">
              <div className="intro text-center px-md-5 mb-5">
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  animateOnce={true}
                >
                  <h2>
                    Our Home is a Living, Breathing, Interconnected and
                    Interdependent Planet
                  </h2>
                </ScrollAnimation>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="content text-center">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      initiallyVisible={false}
                      animateOnce={true}
                    >
                      <a
                        href="https://shop.earthseeds.org/products"
                        alt="Earthseeds Shop"
                      >
                        <figure className="img-circle">
                          <img
                            className="img-fluid"
                            src="/assets/img/living-earth.jpg"
                            alt=""
                          />
                        </figure>
                        <h4>The Fine Art Gallery Wraps</h4>
                      </a>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content text-center">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      delay={400}
                      initiallyVisible={false}
                      animateOnce={true}
                    >
                      <Link to="/earthseeds-project">
                        <figure className="img-circle">
                          <img
                            className="img-fluid"
                            src="/assets/img/living-earth2.jpg"
                            alt=""
                          />
                        </figure>
                        <h4>The EarthSeeds Project</h4>
                      </Link>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content text-center">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      delay={600}
                      initiallyVisible={false}
                      animateOnce={true}
                    >
                      <a
                        href="https://shop.earthseeds.org/products/16-astronauts-globe-inflatable-earth"
                        alt="Astronaut's Globe"
                      >
                        <figure className="img-circle">
                          <img
                            className="img-fluid"
                            src="/assets/img/living-earth1.jpg"
                            alt=""
                          />
                        </figure>
                        <h4>The Astronaut’s Globe</h4>
                      </a>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="component core-mission bg-black"
          style={{
            backgroundImage: 'url("assets/img/bg3.jpg")',
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <h2 className="text-white">Our Core Mission</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="intro">
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    initiallyVisible={false}
                    animateOnce={true}
                  >
                    <iframe
                      src="https://player.vimeo.com/video/388302708"
                      title="EarthSeeds 2004 Flash Remix Ecliptic Imagery"
                      allowFullScreen
                      frameBorder={0}
                      width="100%"
                      height="427px"
                    />
                    <h5 className="text-capitalize text-white">
                      To Promote The Astronaut’s View of Earth in Space And
                      Create Opportunities For People To Experience The Overview
                      Effect. Whole Earth photographs AND how they impact the
                      way we feel and think are called EarthSeeds, the most
                      important aspect of our educational outreach materials for
                      children and adults.
                    </h5>
                    <div className="d-flex justify-content-start mt-4">
                      <Link
                        className="btn btn-donate hvr-shadow"
                        to="/planet-in-every-classroom"
                      >
                        BUY EARTH ART TO<span>sponsor a classroom</span>
                      </Link>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
              <div className="col-md-6">
                <div className="big-video">
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    initiallyVisible={false}
                    animateOnce={true}
                  >
                    <LazyLoadComponent>
                      <iframe
                        title="video"
                        allowFullScreen
                        frameBorder={0}
                        width="100%"
                        height="545px"
                        src="https://player.vimeo.com/video/215744556"
                      />
                    </LazyLoadComponent>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div className="intro text-center my-5">
              <ScrollAnimation
                animateIn="fadeIn"
                initiallyVisible={false}
                animateOnce={true}
              >
                <h2 className="text-white">
                  360<sup>o</sup> Virtual Tour of Earth from Space
                </h2>
                <p className="text-white">
                  This new fundamental human experience of seeing Earth in Space
                  is only 70 years old, one that we believe is underserved.
                  Interacting with photographs of our planet and accurate 3D
                  representations of Earth have measurable benefits for children
                  and adults of all ages. Genuine self-reflection while
                  observing Earth can have a profound impact on your sense of
                  identity, self- esteem and purpose in life. Some people have
                  felt great joy, compassion and even love!
                </p>
              </ScrollAnimation>
            </div>

            <div className="row">
              <div className="col-12">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  initiallyVisible={false}
                  animateOnce={true}
                >
                  <LazyLoadComponent>
                    <iframe
                      title="video"
                      allowFullScreen
                      frameBorder={0}
                      width="100%"
                      height={800}
                      src="https://velocityvirtualtours.com/tours/blue-marble-2/"
                    />
                  </LazyLoadComponent>
                  <div className="d-flex flex-wrap justify-content-center my-5">
                    <Link
                      className="d-block btn btn-link hvr-shadow mb-3 mr-md-3"
                      to="/earthseeds-project"
                    >
                      EarthSeeds Project
                    </Link>
                    <Link
                      className="d-block btn btn-link-inverted hvr-shadow mb-3"
                      to="/earthseeds-project"
                    >
                      Planet Earth Gifts
                    </Link>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
        <div id="top">
          <Link hash="#index">
            <img
              className="mb-1"
              src="/assets/img/arrow-pointing-to-right.svg"
              alt="top"
            />
            <span>Top</span>
          </Link>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
